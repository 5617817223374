<template>
  <v-form lazy-validation v-model="valid" ref="form">
    <v-row>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-subheader>Middle <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="12" sm="12" md="3" lg="3" class="py-0">
            <v-text-field
              v-model="params.formatOne"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || '3 Digit is required', v => v.length <= 3 || 'Max 3 characters']"
              counter="3"
              label="3 Digit"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
            <v-text-field
              v-model="params.formatTwo"
              outlined
              dense
              flat
              background-color="white"
              :rules="[
                v => !!v || 'Delimeter is required',
                v => v.length <= 1 || 'Max 1 characters',
              ]"
              counter="1"
              label="Delimeter"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="1" lg="1" class="py-0">
            <v-text-field
              v-model="params.formatThree"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Year is required', v => v.length <= 2 || 'Max 2 characters']"
              counter="2"
              label="Year (2 Digit)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Begin Number <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model.number="form.beginNumber"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Begin is required']"
              type="number"
              @input="onInputBegin"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
            <v-subheader>Total Number <span class="red--text">*</span></v-subheader>
          </v-col>
          <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
            <v-text-field
              v-model.number="form.maxNumber"
              outlined
              dense
              flat
              background-color="white"
              :rules="[v => !!v || 'Max is required']"
              type="number"
              @input="onInputMax"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="py-0" cols="12">
        <v-btn class="mr-4" @click="submit" color="primary">
          submit
        </v-btn>
        <v-btn @click="clear">
          clear
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          sort-by="no"
          dense
          disable-pagination
          hide-default-footer
        ></v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import moment from "moment";

export default {
  name: "create-tax-number",
  computed: {
    setFormat() {
      return this.params.formatOne + this.params.formatTwo + this.params.formatThree;
    },
  },
  watch: {
    setFormat(val) {
      this.form.middle = val;
    },
  },
  data: () => ({
    defaultForm: {
      middle: "",
      beginNumber: 0,
      maxNumber: 0,
    },
    defaultParams: {
      formatOne: "",
      formatTwo: "-",
      formatThree: moment().format("YY"),
    },
    form: {},
    params: {},
    items: [],
    valid: true,
    headers: [
      {
        text: "No",
        align: "center",
        sortable: false,
        value: "no",
        divider: true,
        class: "light-blue lighten-2 font-weight-black",
      },
      {
        text: "Serial Number",
        value: "serialNumber",
        sortable: false,
        divider: true,
        align: "center",
        class: "light-blue lighten-2 font-weight-black",
      },
    ],
  }),

  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.$store.commit("SET_LOADER", true);
        await this.$store
          .dispatch("taxNumber/create", this.form)
          .then(() => this.$store.commit("SET_LOADER", false))
          .catch(() => {
            this.$store.dispatch("toast", {
              type: "error",
              message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
            });
            this.$store.commit("SET_LOADER", false);
          });
      }
    },
    clear() {
      this.form = Object.assign({}, this.defaultForm);
      this.params = Object.assign({}, this.defaultParams);
      this.items = [];
    },
    onInputMax() {
      this.items = [];
      for (let index = 0; index < this.form.maxNumber; index++) {
        this.items.push({
          no: index + 1,
          serialNumber: this.form.middle + "." + (this.form.beginNumber + index),
        });
      }
    },
    onInputBegin() {
      this.items = [];
      for (let index = 0; index < this.form.maxNumber; index++) {
        this.items.push({
          no: index + 1,
          serialNumber: this.form.middle + "." + (this.form.beginNumber + index),
        });
      }
    },
  },

  mounted() {
    this.form = Object.assign({}, this.defaultForm);
    this.params = Object.assign({}, this.defaultParams);
  },
};
</script>

<style></style>
